#sidebar .sidebar-item > .sidebar-link > i {
  display: inline-block;
  width: 18px;
  text-align: center;
}

#sidebar .sidebar-dropdown .sidebar-item > .sidebar-link > i {
  width: 24px;
  margin-right: 0.2rem;
  text-align: center;
  color: #afb8c8;
  font-size: 16px;
}

#sidebar .sidebar-dropdown .sidebar-item.active > .sidebar-link > i {
  color: #153d77;
}
